import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "./WhatClientsSay.scss"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SectionHeader from "../../../components/SectionHeader"
import TestimonialCards from "../../../components/TestimonialCards/index"

import {CustomLinks} from "../../../components/common/menuUtils"
import { useLocation } from "@reach/router"
const WhatClientsSay = ( { Module } ) => {
	const location = useLocation();
	const locat = location.pathname;
	let moduleTitle = Module.Title;
	let linkFulltext = "View All Testimonials";
	let linkShorttext = "View All";
	if(locat == "/about-residential-land/charity-stories/"){
		moduleTitle = 'What do our tenants think?'
		linkShorttext = 'View More'
		linkFulltext = 'View more'
	}
	const sectionHeaderData = { 
		Title: moduleTitle,
		SubTitle: Module.Content,
		showSubTitleAlways: true,
		Link: {
			FullText: linkFulltext,
			ShortText: linkShorttext,
			Href: "/"+CustomLinks.testimonials+"/"
		}
	}

	return (
		<>
			{ (Module?.Reviews_Module && Module?.Reviews_Module.length > 0) && (
				<SectionWrapper className="what-clients-say-wrapper">
					<Container>
						<Row className="what-clients-say-header">
							<Col lg={ 1 }>
								{/* <img
									className="quote-image"
									src={ require( `../../../images/testimonial-quote.svg` ).default }
								/> */}
								<i className="icon icon-quote-up"></i>
							</Col>
							<Col className="what-clients-say-header-content">
								<span className="testimonials-small-title d-block d-sm-none">
									TESTIMONIALS
								</span>
								<SectionHeader
									sectionHeaderData={ sectionHeaderData }
								/>
							</Col>
						</Row>

						<div className="sub-section-wrapper">
							<TestimonialCards
								testimonialsCardsData={ Module?.Reviews_Module }
							/>
						</div>

					</Container>
				</SectionWrapper>
			) }
		</>
	)
}

export default WhatClientsSay
