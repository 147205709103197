import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import { useLocation } from "@reach/router"
// Styles
import "./IntroSection.scss"

// Components
import SectionWrapper from "../SectionWrapper"

import AccordianSection from "../AccordianSection"
import RelatedPageSection from "../RelatedPageSection"
import RIghtSideSection from "./RIghtSideSection"
import Contact_Form from "../forms/contactussidebar-form";

const IntroSection = ( { Module, RelatedPages, Contact_Details, Accordians, Alias, setPlayVideo } ) => {
    const location = useLocation();
    const pathloc  = location.pathname
    const [isDesktop, setIsDesktop] = React.useState(false);
    React.useEffect(() => {
        const updateScreenSize = () => {
            setIsDesktop(window.innerWidth > 1200);
        };
        updateScreenSize();
        window.addEventListener("resize", updateScreenSize);
        return () => {
            window.removeEventListener("resize", updateScreenSize);
        };
    }, []);

    return (
        <SectionWrapper className="intro-section-wrapper">
            
            <Container>
                <Row className={`intro-section-header ${Module.Video_URL ? "vid_cnt" : ""}`}>
                    
                    <div className={`intro_inner ${Module.Right_Side_Image && Module.Video_URL === null ? "width_50" :"" } ${(Module.Right_Side_Module || Module.Right_Side_Image) ? "sml_wdth" : ""}`}>
                        <Col lg="11">
                            {Module.Title && <h2 className="general-heading">{Module.Title}</h2>}
                            <div className="content_section">
                                {Module.Content && HTMLReactParser(Module.Content)}
                            </div>
                        </Col>

                        {Accordians?.length > 0 && Accordians?.map((Module, index) => {
                            return(
                                <AccordianSection Module={Module}/>
                            )
                        })}

                        {Module.Accordian_Section.length > 0 && <AccordianSection Module={{"Accordian_Module":Module.Accordian_Section}}/>}

                        {RelatedPages?.length > 0 && <RelatedPageSection Module={RelatedPages[0]} ColLg={Module.Right_Side_Module || Module.Right_Side_Image ? "6" : "4"} ColMd={Module.Right_Side_Module || Module.Right_Side_Image ? "12" : "6"} />}
                    </div>
                    {(Module.Right_Side_Module || Module.Right_Side_Image) && 
                        <div className={`right_side_section ${Module.Right_Side_Image && Module.Video_URL === null ? "width_50" : ""}`}>
                            <RIghtSideSection Right_Side_Module={Module.Right_Side_Module} Right_Side_Image={Module.Right_Side_Image} Embed_Video_URL={Module.Video_URL} Contact_Details={Contact_Details} Team_Module={Module.Team_Module} Alias={Alias} setPlayVideo={setPlayVideo} />
                            {isDesktop && location.pathname === '/contact-us' && <Contact_Form />}
                            </div>
                    }
                </Row>                
            </Container>
        </SectionWrapper>
    )
}

export default IntroSection